<template>
    <psi-stats-card
        class="ml-2"
        :colorOption="3"
        icon="mdi-aspect-ratio"
        title="Income to Rent Ratio"
        :stat="ratio"
        :action="false"
        hide-actions
    >
    </psi-stats-card>
</template>
<script>
export default {
    name: "screening-household-ration",
    components: {},
    props: {
        currentApplication: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ratio: {
            immediate: true,
            deep: true,
            get() {
                return (
                    this.currentApplication.applicants[0].order
                        .household_summary.income_total /
                    parseFloat(
                        this.currentApplication.rent
                            .replace("$", "")
                            .replace(",", "")
                    )
                ).toLocaleString("en-US", { maximumFractionDigits: 1 });
            },
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>