var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("psi-stats-card", {
    staticClass: "ml-2",
    attrs: {
      colorOption: 3,
      icon: "mdi-aspect-ratio",
      title: "Income to Rent Ratio",
      stat: _vm.ratio,
      action: false,
      "hide-actions": ""
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }